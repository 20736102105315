import request from '@/utils/request'

export function preScale(fileID) {
  return request({
    url: 'MailScale/PreScale?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}


export function submitMailScale(params) {
  return request({
    url: '/MailScale/SubmitScale',
    method: 'post',
    data: params || {
      express: null,
      scales: []
    }
  });
}


export function fetchExpressList(params) {
  return request({
    url: '/MailExpress/Search',
    method: 'post',
    data: params
  });
}


export function fetchExpressDetail(id) {
  return request({
    url: '/MailScale/Details/' + id,
    method: 'get'
  });
}


export function delExpress(id) {
  return request({
    url: '/MailExpress/' + id,
    method: 'delete'
  });
}





export function fetchBillMasterList(params) {
  return request({
    url: '/MailBill/Search',
    method: 'post',
    data: params
  });
}

export function PreBill(fileID) {
  return request({
    url: 'MailBill/PreBill?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}


export function submitMailBill(params) {
  return request({
    url: '/MailBill/SubmitBill',
    method: 'post',
    data: params || {
      master: null,
      details: []
    }
  });
}

export function exportMailBill(id) {
  return request({
    url: '/MailBill/ExportByID?id=' + (id || ''),
    method: 'post'
  });
}


export function fetchBillInfo(masterId) {
  return request({
    url: '/MailBill/Details/' + masterId,
    method: 'get'
  });
}

export function delBillMaster(id) {
  return request({
    url: '/MailBill/' + id,
    method: 'delete'
  });
}







export function fetchOtherMasterList(params) {
  return request({
    url: '/MailOther/Search',
    method: 'post',
    data: params
  });
}
export function delOtherMaster(id) {
  return request({
    url: '/MailOther/' + id,
    method: 'delete'
  });
}


export function PreOtherBill(fileID) {
  return request({
    url: 'MailOther/PreBill?FileID=' + fileID,
    method: 'get',
    loadTarget: 'load-dialog'
  });
}


export function submitOtherBill(params) {
  return request({
    url: '/MailOther/SubmitBill',
    method: 'post',
    data: params || {
      master: null,
      details: []
    }
  });
}


export function fetchOtherInfo(masterId) {
  return request({
    url: '/MailOther/Details/' + masterId,
    method: 'get'
  });
}


export function exportOtherBill(id) {
  return request({
    url: '/MailOther/ExportByID?id=' + (id || ''),
    method: 'post'
  });
}


export function fetchPartner(id) {
  return request({
    url: '/MailPartner/' + id,
    method: 'get'
  });
}
export function fetchPartnerList(params) {
  return request({
    url: '/MailPartner/Search',
    method: 'post',
    data: params
  });
}
export function delPartners(id) {
  return request({
    url: '/MailPartner/' + id,
    method: 'delete'
  });
}

export function submitPartner(data) {
  return request({
    url: '/MailPartner/' + (data.id || ''),
    method: data.id ? 'put' : 'post',
    data: data
  });
}




export function fetchMonthBill(id) {
  return request({
    url: '/MailMonthBill/' + id,
    method: 'get'
  });
}
export function fetchMonthBillList(params) {
  return request({
    url: '/MailMonthBill/Search',
    method: 'post',
    data: params
  });
}
export function delMonthBills(id) {
  return request({
    url: '/MailMonthBill/' + id,
    method: 'delete'
  });
}
export function submitMonthBill(data) {
  return request({
    url: '/MailMonthBill/' + (data.id || ''),
    method: data.id ? 'put' : 'post',
    data: data
  });
}


export function importEmsScale(fileID, PartnerID, CoverMark) {
  return request({
    url: 'MailEmsScale/ImportScale?FileID=' + fileID + '&PartnerID=' + PartnerID + "&CoverMark=" + (CoverMark || '0'),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}
export function fetchEmsScaleList(partnerID, keyword) {
  var params = {
    "rows": "10000",
    "keyword": keyword || "",
    "filters": [],
    "columns": [
      { "prop": "expressName", "searchable": true },
      { "prop": "originProvince", "searchable": true },
      { "prop": "originCity", "searchable": true },
      { "prop": "targetProvince", "searchable": true },
      { "prop": "targetCity", "searchable": true },
      { "prop": "feeScale", },
      { "prop": "weightType", },
      { "prop": "createDate", },
      { "prop": "createUserName", },
    ],
    pager: {
      sidx: "id", //默认的排序字段
      sord: "descending",
    },
  };
  params.filters.push({
    name: "partnerID",
    value: partnerID,
    operate: 0,
  });
  return request({
    url: '/MailEmsScale/Search',
    method: 'post',
    data: params
  });
}


export function importOutScale(fileID, PartnerID, CoverMark) {
  return request({
    url: 'MailOutScale/ImportScale?FileID=' + fileID + '&PartnerID=' + PartnerID + "&CoverMark=" + (CoverMark || '0'),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}
export function fetchOutScaleList(partnerID) {
  var params = {
    "rows": "10000",
    "keyword": "",
    "filters": [],
    "columns": [
      { "prop": "bizType", "searchable": true },
      { "prop": "methodName", },
      { "prop": "unitName", },
      { "prop": "unitNumber", },
      { "prop": "unitPrice", },
      { "prop": "sortCode", },
      { "prop": "remark", },
      { "prop": "createDate", },
      { "prop": "createUserName", },
    ],
    pager: {
      sidx: "id", //默认的排序字段
      sord: "descending",
    },
  };
  params.filters.push({
    name: "partnerID",
    value: partnerID,
    operate: 0,
  });
  return request({
    url: '/MailOutScale/Search',
    method: 'post',
    data: params
  });
}


export function importOtrScale(fileID, PartnerID, CoverMark) {
  return request({
    url: 'MailOtrScale/ImportScale?FileID=' + fileID + '&PartnerID=' + PartnerID + "&CoverMark=" + (CoverMark || '0'),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}
export function fetchOtrScaleList(partnerID) {
  var params = {
    "rows": "10000",
    "sidx": "sortCode",
    "sord": "asc",
    "keyword": "",
    "filters": [],
    "columns": [
      { "prop": "bizType", "searchable": true },
      { "prop": "methodName", },
      { "prop": "unitName", },
      { "prop": "unitNumber", },
      { "prop": "unitPrice", },
      { "prop": "sortCode", },
      { "prop": "remark", },
      { "prop": "createDate", },
      { "prop": "createUserName", },
    ],
    pager: {
      sidx: "id", //默认的排序字段
      sord: "descending",
    },
  };
  params.filters.push({
    name: "partnerID",
    value: partnerID,
    operate: 0,
  });
  return request({
    url: '/MailOtrScale/Search',
    method: 'post',
    data: params
  });
}




export function fetchMonthTotal(BillID) {
  return request({
    url: 'MailMonthBill/MonthTotal?BillID=' + BillID,
    method: 'get'
  });
}


export function importMonthEms(fileID, BillID, CoverMark) {
  return request({
    url: 'MailMonthEms/ImportDetail?FileID=' + fileID + '&BillID=' + BillID + "&CoverMark=" + (CoverMark || '0'),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function fetchMonthEmsList(BillID, method) {
  var params = {
    "rows": "10000",
    "keyword": "",
    "filters": [],
    "columns": [
      { "prop": "bizType", "searchable": true },
      { label: "快递公司", "prop": "companyName", },
      { label: "付款方式", "prop": "emsPayType", },
      { label: "快递单号", "prop": "detailNo", },
      { label: "寄出省份", "prop": "originProvince", },
      { label: "寄出城市", "prop": "originCity", },
      { label: "目标省份", "prop": "targetProvince", },
      { label: "目标城市", "prop": "targetCity", },
      { label: "快递类型", "prop": "expressName", },
      { label: "计费重量", "prop": "emsWeight", },
      { label: "运费", "prop": "emsPayFee", },
      { label: "系统计算费用", "prop": "emsSysFee", },
      { label: "核价状态", "prop": "status", },
      { label: "备注", "prop": "remark", },
      { label: "导入时间", "prop": "createDate", },
      { label: "导入人", "prop": "createUserName", },
    ],
    pager: {
      sidx: "id", //默认的排序字段
      sord: "descending",
    },
  };
  params.filters.push({
    name: "BillID",
    value: BillID,
    operate: 0,
  });
  params.method = method || '';
  return request({
    url: '/MailMonthEms/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}




export function importMonthOut(fileID, BillID, CoverMark) {
  return request({
    url: 'MailMonthOut/ImportDetail?FileID=' + fileID + '&BillID=' + BillID + "&CoverMark=" + (CoverMark || '0'),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function fetchMonthOutList(BillID, method) {
  var params = {
    "rows": "10000",
    "keyword": "",
    "filters": [],
    "columns": [
      { label: "业务类型","prop": "bizType", },
      { label: "货主","prop": "productOwner", },
      { label: "出库单号","prop": "outOrderNo", },
      { label: "订单类型","prop": "orderType", },
      { label: "业务号码","prop": "bizNo", },
      { label: "建单时间","prop": "billDate", },
      { label: "首状态","prop": "firstState", },
      { label: "尾状态","prop": "endedState", },
      { label: "处理类型","prop": "dealType", },
      { label: "复核完成时间","prop": "checkDate", },
      { label: "发运时间","prop": "sendDate", },
      { label: "快递单号","prop": "emsNo", },
      { label: "快递公司","prop": "companyName", },

      { label: "纸箱编号","prop": "boxNo", },
      { label: "省份","prop": "province", },
      { label: "城市","prop": "city", },
      { label: "平台","prop": "platformNo", },
      { label: "品牌","prop": "brandNo", },
      { label: "物料编码","prop": "materialCode", },
      { label: "物料名称","prop": "materialName", },
      { label: "首件","prop": "firstNumber", },
      { label: "续件","prop": "additNumber", },
      { label: "个数","prop": "number", },
      { label: "箱含量","prop": "boxUnit", },
      { label: "箱数","prop": "boxNumber", },
      { label: "体积","prop": "volume", },
      { label: "重量","prop": "weight", },
      { label: "费用","prop": "outPayFee", },
      { label: "系统计算费用","prop": "outSysFee", },

      { label: "核价状态","prop": "status", },
      { label: "备注","prop": "remark", },
      { label: "导入时间","prop": "createDate", },
      { label: "导入人","prop": "createUserName", },
    ]
  };
  params.filters.push({
    name: "BillID",
    value: BillID,
    operate: 0,
  });
  params.method = method || '';
  return request({
    url: '/MailMonthOut/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}





export function importMonthPut(fileID, BillID, CoverMark) {
  return request({
    url: 'MailMonthPut/ImportDetail?FileID=' + fileID + '&BillID=' + BillID + "&CoverMark=" + (CoverMark || '0'),
    method: 'get',
    loadTarget: 'load-dialog'
  });
}

export function fetchMonthPutList(BillID, method) {
  var params = {
    "rows": "10000",
    "keyword": "",
    "filters": [],
    "columns": [
      { label: "区域/仓库","prop": "storeName", },
      { label: "货主","prop": "productOwner", },
      { label: "入库时间","prop": "putDate", },
      { label: "物料编码","prop": "materialCode", },
      { label: "物料名称","prop": "materialName", },
      { label: "实收个数","prop": "number", },
      { label: "实收体积","prop": "boxNumber", },
      { label: "实收箱数","prop": "volume", },

      { label: "入库备注","prop": "operateRemark", },
      { label: "导入时间","prop": "createDate", },
      { label: "导入人","prop": "createUserName", },
    ]
  };
  params.filters.push({
    name: "BillID",
    value: BillID,
    operate: 0,
  });
  params.method = method || '';
  return request({
    url: '/MailMonthPut/' + (params.method || 'Search'),
    method: 'post',
    data: params
  });
}





export function fetchMonthEmsPager(params,BillID, method) {
  params.filters.push({
    name: "BillID",
    value: BillID,
    operate: 0,
  });
  return request({
    url: '/MailMonthEms/'+ (method || 'Search'),
    method: 'post',
    data: params
  });
}

export function fetchMonthOutPager(params,BillID, method) {
  params.filters.push({
    name: "BillID",
    value: BillID,
    operate: 0,
  });
  return request({
    url: '/MailMonthOut/'+ (method || 'Search'),
    method: 'post',
    data: params
  });
}