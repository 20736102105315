<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">快递业务</el-breadcrumb-item>
        <el-breadcrumb-item>导入出库业务单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <div v-if="!details && !id" class="storedialog load-dialog" style="text-align: center">
      <div class="download" style="margin: 10px auto">
        点击下载模板：
        <a target="_blank" :href="templateUrl"> 出库业务单导入模板.xlsx </a>
      </div>

      <el-upload v-loading="loading" class="upload-box" ref="upload" action="#" :http-request="submitUpload"
        :auto-upload="true" drag :limit="1">
        <el-icon style="font-size:3em;">
          <UploadFilled />
        </el-icon>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip">
          注：Excel表格里的填写项请严格按照模板内容进行填写
        </div>
      </el-upload>
    </div>

    <div v-if="details || id">
      <el-form :model="master" ref="master" :rules="rules" class="detail-form" @submit.prevent>
        <el-row>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label="费用项目：" prop="bizType">
              <el-col>
                <el-input v-model="master.bizType"> </el-input>
              </el-col>
            </el-form-item>
          </el-col>

          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label-width="130px" label="账单日期:" prop="billDate">
              <el-col>
                <el-date-picker v-model="master.billDate" type="date" placeholder="" format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss" />
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
            <el-form-item label="备注信息：" prop="remark">
              <el-input type="textarea" placeholder="请输入备注内容" v-model="master.remark" maxlength="1000" show-word-limit
                :rows="2" style="width: 88%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18"> </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6" style="text-align: right">
            <el-form-item>
              <el-button type="primary" icon="check" @click="submitEvent" v-if="!master.id">
                保存
              </el-button>
              <el-button type="info" icon="back" @click="back()">
                返回
              </el-button>

              <el-button v-if="master.id" type="primary" @click="exportEvent" icon="download">
                导出
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-table class="table" ref="detailTable" :data="details" border size="small" height="450">
        <!-- <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column> -->
        <el-table-column prop="sortCode" label="序号" align="center" width="50"> </el-table-column>
        <el-table-column prop="bizType" label="费用项目" width="110"> </el-table-column>
        <el-table-column prop="operationNo" label="出库单号" sortable width="160">
          <template #default="scope">
            <el-tooltip v-if="scope.row.status == -1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="danger" size="small" disabled>{{ scope.row.operationNo }}</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == 1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="success" size="small" disabled>{{ scope.row.operationNo }}</el-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="billDate" label="建单时间" width="100">
          <template #default="scope">
            {{ scope.row.billDate.split(' ')[0] }}
          </template>
        </el-table-column>
        <el-table-column prop="detailDate" label="发运时间" width="100">
          <template #default="scope">
            {{ scope.row.detailDate.split(' ')[0] }}
          </template>
        </el-table-column>

        <el-table-column prop="detailNo" label="快递单号" width="140"> </el-table-column>

        <el-table-column prop="expressName" label="快递公司" width="80"> </el-table-column>
        <el-table-column prop="targetProvince" label="目标省份" width="100"> </el-table-column>
        <el-table-column prop="targetCity" label="目标城市" width="100"> </el-table-column>
        <el-table-column prop="platformName" label="平台" width="90"> </el-table-column>
        <el-table-column prop="materialCode" label="物料编码" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="materialName" label="物料名称" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="number" label="个数" width="50"> </el-table-column>
        <el-table-column prop="boxUnit" label="箱含量" width="60"> </el-table-column>
        <el-table-column prop="boxNumber" label="箱数" width="50"> </el-table-column>
        <el-table-column prop="volume" label="体积" width="50"> </el-table-column>
        <el-table-column prop="weight" label="重量" width="50"> </el-table-column>

        <el-table-column prop="firstNumber" label="首件" width="50"> </el-table-column>
        <el-table-column prop="additNumber" label="续件" width="50"> </el-table-column>

        <el-table-column prop="scaleFee" label="仓操作费" width="80"> </el-table-column>
        <el-table-column prop="systemFee" label="系统计算费用" width="100"> </el-table-column>
        <!-- <el-table-column prop="remark" label="备注" width="180" :show-overflow-tooltip="true" > </el-table-column> -->
        <el-table-column prop="remark" label="备注" sortable width="180">
          <template #default="scope">
            <el-tooltip v-if="scope.row.status == -1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="danger" size="small" disabled>{{ scope.row.remark }}</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == 1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="success" size="small" disabled>{{ scope.row.remark }}</el-link>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import { uploader } from "@/api/subupload";
import { PreOtherBill, submitOtherBill, fetchOtherInfo, exportOtherBill } from "@/api/mailscale";
export default {
  name: "otherDetail",
  data() {
    return {
      templateUrl: process.env.VUE_APP_BASEURL + "/static/template/mailother.xlsx",
      master: {},
      details: null,
      id: null,

      loading: false,
      rules: {
        bizType: [
          { required: true, message: "请输入费用项目", trigger: "blur" },
        ],
        billDate: [
          { required: true, message: "请输入账单日期", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    //上传操作
    submitUpload(file) {
      this.loading = true;
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", "MailScale");
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success" });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          PreOtherBill(this.dataId).then(
            (res) => {
              if (res.code === 200) {
                console.log(Object.values({ ...res.data }));
                this.details = res.data;
              }
            },
            (err) => {
              console.log(err);
            }
          );
        });
    },

    submitEvent() {
      let dtos = {
        master: this.master,
        details: this.details,
      };
      dtos.master.billDate = this.$util.fmtDate(dtos.master.billDate, "yyyy-MM-dd");
      console.log(dtos);
      let that = this;

      this.$refs.master.validate((valid) => {
        if (!valid) return;

        submitOtherBill(dtos).then((res) => {
          that.$message({ type: res.type, message: res.content });
          if (res.code == 200) {
            //that.$router.back(-1);
            that.master = res.data.master;
            that.details = res.data.details;
          }
        });
      });
    },

    fetchs(masterId) {
      let that = this;
      fetchOtherInfo(masterId).then((res) => {
        that.master = res.data.master;
        that.details = res.data.details;
        that.rules = [];
      });
    },
    //打印
    onprint() {
      print(this.$refs.print);
    },
    back() {
      this.$router.go(-1);
    },

    exportEvent() {
      exportOtherBill(this.id).then((res) => {
        if (res.code == 200)
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.id) this.fetchs(this.id);
  },
};
</script>
<style lang="less"></style>