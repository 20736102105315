import request from '@/utils/request'

export function uploader(data) {
  return request({
    url: 'BaseUploader/Simple',
    method: 'post',
    data: data,
    loadTarget:'load-dialog'
  });
}

export function inReviewer(fileID) {
  return request({
    url: 'StockBills/InReviewer?FileID=' + fileID,
    method: 'get',
    loadTarget:'load-dialog'
  });
}


export function outReviewer(fileID) {
  return request({
    url: 'StockBills/OutReviewer?FileID=' + fileID,
    method: 'get',
    loadTarget:'load-dialog'
  });
}